import { getTimezone, isAuthenticated, loadedUserData, logout } from './common/helpers';
import { environment } from 'environments/environment';

// Ensure the CSRF cookie is set. Then, if needed, get the authenticated User.
export const initializeApp = () =>
  // Set CORS cookies, required for calls to backend.
  // It uses XMLHttpRequest instead of HttpClient to minimize dependencies.
  () => new Promise<void>((resolve, reject) => {
    const readyStateChange = () => {
      if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 204) {
        if (isAuthenticated()) {
          // LocalStorage thinks we are authenticated. Let's get the User from API,
          // to make sure the session is valid, and to have the User before doing anything else.
          xhr.removeEventListener('readystatechange', readyStateChange);
          xhr.open('GET', environment.API_DOMAIN + '/api/user?timezone=' + getTimezone(), true);
          xhr.setRequestHeader('Accept', 'application/json');
          xhr.addEventListener('readystatechange', () => {
            if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
              loadedUserData(xhr.responseText);
              resolve();
            } else if (xhr.readyState === XMLHttpRequest.DONE) {
              logout({keepPartner: true});
              reject('Session seems invalid. Returning to login screen.');
            }
          });
          xhr.send();
        } else {
          resolve();
        }
      } else if (xhr.readyState === XMLHttpRequest.DONE) {
        reject('Cannot connect to server.');
        alert('Cannot connect to the Flxion server. Please check your internet connection and refresh.');
      }
    };

    const xhr = new XMLHttpRequest();
    xhr.open('GET', environment.API_DOMAIN + '/sanctum/csrf-cookie', true);
    xhr.withCredentials = true; // Ensure cookies are sent with the request
    xhr.addEventListener('readystatechange', readyStateChange);
    xhr.send();
  });
